<template>
  <div>
    <div class="yusu-home-main">
      <section class="yusu-home-section yusu-home-banner">
        <swiper
          ref="mySwiper"
          class="swiper"
          :options="swiperOption"
          :slides-per-view="3"
          :space-between="50"
          :pagination="{ clickable: true }"
        >
          <swiper-slide><img :src="banner1" alt="" /></swiper-slide>
          <swiper-slide
            ><img :src="banner2" alt="" 
          /></swiper-slide>
          <swiper-slide><img :src="banner3" alt="" /></swiper-slide>
          <div class="swiper-pagination" slot="pagination" @click="onBannerOneClick"></div>
        </swiper>
      </section>
      <section class="yusu-home-section yusu-home-second">
        <img :src="imgBg02" alt=""  @click="onGotoPage('/expert')"/>
      </section>
      <section class="yusu-home-section yusu-home-video">
        <!-- <img :src="videoBg03" alt="" /> -->
        <!-- <div class="yc-videos">
          
        </div> -->
        <swiper :options="swiperOptionVideo">
            <swiper-slide v-for="(item, index) in videos" :key="index">
              <img :src="item.bg" alt="" />
              <div class="yc-videos">
                <video
                  :src="item.video"
                  :poster="item.jpg"
                  controls
                  ref="video"
                ></video>
              </div>
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
      </section>
      <section class="yusu-home-section yusu-home-bestSeller">
        <img :src="imgBg04" alt="" />
        <div class="yc-products">
          <swiper :options="swiperOptionPro" :slides-per-view="3" :space-between="50" >
            <swiper-slide>
              <img
                :src="product01"
                alt=""
                class="yc-prodcut-img"
                @click="onGotoPage('/top10/detail', 7)"
              />
              <img
                :src="product02"
                alt=""
                class="yc-prodcut-img"
                @click="onGotoPage('/top10/detail', 5)"
              />
              <img
                :src="product03"
                alt=""
                class="yc-prodcut-img"
                @click="onGotoPage('/top10/detail', 8)"
              />
              <img
                :src="product04"
                alt=""
                class="yc-prodcut-img"
                @click="onGotoPage('/top10/detail', 2)"
              />
            </swiper-slide>
            <swiper-slide>
              <img
                :src="product05"
                alt=""
                class="yc-prodcut-img"
                @click="onGotoPage('/top10/detail', 10)"
              />
              <img
                :src="product06"
                alt=""
                class="yc-prodcut-img"
                @click="onGotoPage('/top10/detail', 3)"
              />
              <img
                :src="product07"
                alt=""
                class="yc-prodcut-img"
                @click="onGotoPage('/top10/detail', 1)"
              />
              <img
                :src="product08"
                alt=""
                class="yc-prodcut-img"
                @click="onGotoPage('/top10/detail', 6)"
              />
            </swiper-slide>
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </section>
      <section class="yusu-home-section yusu-home-try" style="cursor: pointer">
        <video class="video" :src="bottomVideo" autoplay loop preload  muted ></video>
        <img :src="imgBg05" alt="" @click="onGotoPage('/welfare')" />
      </section>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  Swiper as SwiperClass,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
} from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'

SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)

// import style
import 'swiper/swiper-bundle.min.css'

import banner1 from '../../assets/images/home/home-bg-01.png'
import banner2 from '../../assets/images/home/home-bg-01-2.png'
import banner3 from '../../assets/images/home/home-bg-01-3.png'
import banner4 from '../../assets/images/home/home-bg-01-4.png'
import imgBg02 from '../../assets/images/home/home-bg-02.png'
import videoBg01 from '../../assets/images/home/video/video01.png'
import videoBg03 from '../../assets/images/home/video/video03.png'
import imgBg04 from '../../assets/images/home/home-bg-04.png'
import imgBg05 from '../../assets/images/home/home-bg-05.png'
import product01 from '../../assets/images/home/product-01.png'
import product02 from '../../assets/images/home/product-02.png'
import product03 from '../../assets/images/home/product-03.png'
import product04 from '../../assets/images/home/product-04.png'
import product05 from '../../assets/images/home/product-05.png'
import product06 from '../../assets/images/home/product-06.png'
import product07 from '../../assets/images/home/product-07.png'
import product08 from '../../assets/images/home/product-08.png'

// import guangdongVideo from '../../assets/images/home/video/guangdong.mp4'
import guangdongJPG from '../../assets/images/home/video/guangdong.jpg'
// import guangzhouVideo from '../../assets/images/home/video/guangzhou.mp4'
import guangzhouJPG from '../../assets/images/home/video/guangzhou.jpg'
// import zhejiangVideo from '../../assets/images/home/video/zhejiang.mp4'
import zhejiangJPG from '../../assets/images/home/video/zhejiang.jpg'
// import secondVideo from '../../assets/images/home/video/second.mp4'
import secondJPG from '../../assets/images/home/video/second.jpg'
export default {
  name: 'home',
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
        },
        autoplay: {
          delay: 3000,
        },
      },
      swiperOptionVideo: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // autoplay: {
        //   delay: 6000,
        // },
      },
      swiperOptionPro: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        autoplay: {
          delay: 6000,
        },
      },
      bottomVideo:
        'https://xcxyc.oss-cn-hangzhou.aliyuncs.com/yusu/media/homeBg.mp4',
      banner1: banner1,
      banner2: banner2,
      banner3: banner3,
      banner4: banner4,
      imgBg02: imgBg02,
      videoBg03: videoBg03,
      imgBg04: imgBg04,
      imgBg05: imgBg05,
      product01: product01,
      product02: product02,
      product03: product03,
      product04: product04,
      product05: product05,
      product06: product06,
      product07: product07,
      product08: product08,
      videos: [
        {
          video:
            'https://xcxyc.oss-cn-hangzhou.aliyuncs.com/yusu/media/guangzhou.mp4',
          jpg: guangzhouJPG,
          bg: videoBg03,
        },
        {
          video:
            'https://xcxyc.oss-cn-hangzhou.aliyuncs.com/yusu/media/guangdong.mp4',
          jpg: guangdongJPG,
          bg: videoBg01,
        },
        {
          video:
            'https://xcxyc.oss-cn-hangzhou.aliyuncs.com/yusu/media/zhejiang.mp4',
          jpg: zhejiangJPG,
          bg: videoBg01,
        },
        {
          video:
            'https://xcxyc.oss-cn-hangzhou.aliyuncs.com/yusu/media/second.mp4',
          jpg: secondJPG,
          bg: videoBg01,
        },
      ],
    }
  },

  methods: {
    onGotoPage(url, query) {
      this.$router.push({
        path: url,
        query: {
          productId: query,
        },
      })
    },
    // 首页顶部的banner切换
    onBannerOneClick(info, dd) {
      debugger
    },
  },
}
</script>
<style lang="scss" >
@keyframes productsAnm {
  to {
    transform: matrix3d(
      0.99961,
      -0.0279216,
      0,
      0,
      0.0279216,
      0.99961,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform-origin: center bottom 0px;
  }
  from {
    transform: matrix(1, 0, 0, 1, 0, 0);
    transform-origin: center bottom 0px;
  }
}
.yusu-home-main {
  .yusu-home-bestSeller {
    position: relative;
    .yc-products {
      width: 100% !important;
      position: absolute;
      top: 61%;
      left: 50%;
      transform: translate(-50%, -50%);
      .swiper-button-prev:after,
      .swiper-container-rtl .swiper-button-next:after {
        content: url('../../assets/images/home/arrow-left.png');
      }
      .swiper-button-prev,
      .swiper-container-rtl .swiper-button-next {
        left: 77px;
        right: auto;
      }
      .swiper-button-next,
      .swiper-container-rtl .swiper-button-prev {
        right: 77px;
        left: auto;
      }
      .swiper-button-next:after,
      .swiper-container-rtl .swiper-button-prev:after {
        content: url('../../assets/images/home/arrow-right.png');
      }
      .swiper-slide {
        display: flex;
        justify-content: center;
      }
      .yc-prodcut-img {
        cursor: pointer;
        width: 22%;
        height: 50%;
      }
      .yc-prodcut-img:hover {
        animation: productsAnm 0.2s linear 0s;
      }
    }
  }
}
.yusu-home-video {
  position: relative;
  .yc-videos {
    // width: 100% !important;
    position: absolute;
    top: 61%;
    left: 50%;
    transform: translate(-50%, -50%);
    video {
      height: auto;
      width: 1600px;
      // border: 1px solid #fff200;
    }
    .swiper-slide {
      display: flex;
      justify-content: center;
    }
    .swiper-button-prev:after,
    .swiper-container-rtl .swiper-button-next:after {
      content: url('../../assets/images/home/arrow-left.png');
    }
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: 77px;
      right: auto;
    }
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      right: 77px;
      left: auto;
    }
    .swiper-button-next:after,
    .swiper-container-rtl .swiper-button-prev:after {
      content: url('../../assets/images/home/arrow-right.png');
    }
  }
}
.yusu-home-main .swiper-pagination {
  .swiper-pagination-bullet {
    height: 14px;
    width: 14px;
    background-color: #fff100;
    border: 1px solid #928a33;
  }
}
.yusu-home-try {
  position: relative;
  height: 896px;
  overflow: hidden;
  .video {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
  }
  img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
  }
}
</style>
