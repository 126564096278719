<template>
  <div class="yusu-chat-pop">
    <div class="yusu-chat-pop-item gzh">
      <img
        :src="popGzhQrcode1"
        alt=""
        v-show="isShowGzhQrcode1"
        class="yusu-chat-pop-gzh-qrcode"
      />
      <img
        :src="popIcon1"
        alt=""
        @mouseenter="onMouseenter('isShowGzhQrcode1')"
        @mouseleave="onMouseleave('isShowGzhQrcode1')"
      />
    </div>
    <div class="yusu-chat-pop-item gzh">
      <img
        :src="popGzhQrcode2"
        alt=""
        v-show="isShowGzhQrcode2"
        class="yusu-chat-pop-gzh-qrcode"
      />
      <img
        :src="popIcon2"
        alt=""
        @mouseenter="onMouseenter('isShowGzhQrcode2')"
        @mouseleave="onMouseleave('isShowGzhQrcode2')"
      />
    </div>
    <div
      class="yusu-chat-pop-item"
      ref="yusuChat"
      @click.stop="messageModal = !messageModal"
    >
      <img :src="popLiuyan" alt="" />
    </div>
    <div class="yusu-chat-pop-item">
      <img :src="popGotop" alt="" @click="backTop" />
    </div>

    <div class="form" v-if="messageModal" @click.stop>
      <div class="form_head">请留言</div>
      <div class="form_inner">
        <div class="item textarea">
          <textarea
            name=""
            cols="30"
            rows="5"
            placeholder="留言内容(必填)"
            id="message"
            v-model="message.message"
          ></textarea>
        </div>
        <div class="item">
          <div class="input nameInput">
            <input
              type="text"
              id="name"
              v-model="message.name"
              placeholder="姓名(必填)"
            />
          </div>
        </div>
        <div class="item">
          <div class="input phoneInput">
            <input
              type="text"
              id="phone"
              v-model="message.phone"
              placeholder="手机(必填)"
            />
          </div>
        </div>
        <div class="item">
          <div class="input wechatInput">
            <input
              type="text"
              id="wechat"
              v-model="message.wechat"
              placeholder="微信(必填)"
            />
          </div>
        </div>
        <div class="item">
          <div class="input addressInput">
            <input
              type="text"
              id="address"
              v-model="message.address"
              placeholder="地址"
            />
          </div>
        </div>
        <div class="btn" @click="submitMessage">发送</div>
      </div>
    </div>
  </div>
</template>

<script>
import popGzhQrcode1 from '../assets/images/gzh-1.png'
import popGzhQrcode2 from '../assets/images/gzh-2.png'
import popIcon1 from '../assets/images/pop-icon-1.png'
import popIcon2 from '../assets/images/pop-chat.png'
import popLiuyan from '../assets/images/pop-liuyan.png'
import popGotop from '../assets/images/pop-go-top.png'
export default {
  name: 'chat-pop',
  data: () => {
    return {
      popIcon1: popIcon1,
      popIcon2: popIcon2,
      popLiuyan: popLiuyan,
      popGzhQrcode1: popGzhQrcode1,
      popGzhQrcode2: popGzhQrcode2,
      popGotop: popGotop,
      isShowGzhQrcode1: false,
      isShowGzhQrcode2: false,
      messageModal: false,
      message: {
        message: '',
        name: '',
        phone: '',
        wechat: '',
        address: '',
      },
    }
  },
  mounted() {
    let _this = this
    window.addEventListener('scroll', this.scrollToTop)
    window.document.body.addEventListener('click', function () {
      _this.messageModal = false
    })
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop)
  },

  methods: {
    onMouseenter(key) {
      this[key] = true
    },
    onMouseleave(key) {
      this[key] = false
    },
    backTop() {
      const that = this
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },
    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      const that = this
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      that.scrollTop = scrollTop
      if (that.scrollTop > 0) {
        that.btnFlag = true
      } else {
        that.btnFlag = false
      }
    },
    submitMessage() {
      let _this = this
      console.log(this.message)
      if (!this.message.message) {
        return alert('请填写留言内容')
      }
      if (!this.message.name) {
        return alert('请填写姓名')
      }
      if (!this.message.phone) {
        return alert('请填写手机号')
      }
      if (!this.message.wechat) {
        return alert('请填写微信')
      }
      this.ajaxRequest({
        url: 'https://onion-board.mongacloud.com/submitMessage',
        method: 'POST',
        type: 'json',
        data: this.message,
        success: function (res) {
          console.log(res)
          _this.message = {
            message: '',
            name: '',
            phone: '',
            wechat: '',
            address: '',
          }
          _this.messageModal = false
          alert('留言成功')
        },
        erro: function (err) {
          console.log(err)
        },
      })
    },
    ajaxRequest(option) {
      // 1. 首先简单验证传进来的参数是否合法
      // if(Object.prototype.toString.call(option) !== '[object, Object]') return undefined;
      // 2. 对参数容错处理
      option.method = option.method ? option.method.toUpperCase() : 'GET' // 默认 GET 请求
      option.data = option.data || {}
      option.type = option.type || 'json'

      // 3. 如果是 GET 请求，需要处理 data 里的数据并且以一定的规则拼接到 url 后
      var formData = []
      for (let key in option.data) {
        // Object.keys.forEach
        formData.push(''.concat(key, '=', option.data[key]))
      }
      option.data = formData.join('&') //eg: a=b&c=d&e=f
      if (option.method === 'GET' && formData.lenght > 0) {
        // 注意，option.data 为空对象的时候，就不用拼接参数了
        // 连接本身有参数的时候拼接 &option.data,如果本身没有参数拼接 ?option.data
        option.url +=
          location.search.length === 0
            ? ''.concat('?', option.data)
            : ''.concat('&', option.data)
      }
      // 4. 实例化 XMLHttpRequest 对象,并进行一些设置
      var xhr = new XMLHttpRequest()
      xhr.responseType = option.type
      xhr.withCredentials = false //指示是否应使用Cookie或授权标头等凭据进行跨站点访问控制请求。
      // 5. 处理请求回调
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            if (option.success && typeof option.success === 'function') {
              option.success(xhr.response)
            }
          } else {
            if (option.error && typeof option.error === 'function') {
              option.error(new Error(xhr.statusText))
            }
          }
        }
      }
      xhr.open(option.method, option.url, true) // true 代表是异步请求
      // 6. 如果是 POST 请求，需要设置请求头
      if (option.method === 'POST') {
        xhr.setRequestHeader(
          'Content-Type',
          'application/x-www-form-urlencoded'
        )
      }
      // 7. 发送请求
      xhr.send(option.method === 'POST' ? option.data : null)
    },
  },
}
</script>
<style lang="scss" scoped>
.yusu-chat-pop {
  position: fixed;
  right: 60px;
  bottom: 10%;
  width: 70px;
  .yusu-chat-pop-item {
    margin: 10px 0;
    cursor: pointer;
    position: relative;
  }
  .yusu-chat-pop-item.gzh {
    position: relative;
    .yusu-chat-pop-gzh-qrcode {
      position: absolute;
      right: 80px;
      top: 0;
      max-width: none;
    }
  }
  z-index: 10000;
  .form {
    position: absolute;
    top: -200px;
    right: 80px;
    width: 340px;
    background: #fff100;
    padding: 0 5px 10px 5px;
    .form_head {
      height: 50px;
      font-size: 25px;
      line-height: 50px;
      text-align: right;
      padding-right: 10px;
    }
    .form_inner {
      background: #fff;
      overflow: hidden;
      padding: 0 10px;
    }
    .item {
      height: 40px;
      margin-top: 15px;
      .input {
        height: 100%;
        border: 1px solid #b7b7b7;
        padding-left: 60px;
        &.nameInput {
          background: url('~@/assets/images/common/name.png') no-repeat 10px
            center;
          background-size: auto 26px;
        }
        &.phoneInput {
          background: url('~@/assets/images/common/phone.png') no-repeat 10px
            center;
          background-size: auto 26px;
        }
        &.wechatInput {
          background: url('~@/assets/images/common/wechat.png') no-repeat 10px
            center;
          background-size: auto 26px;
        }
        &.addressInput {
          background: url('~@/assets/images/common/address.png') no-repeat 10px
            center;
          background-size: auto 26px;
        }
      }
      input,
      textarea {
        display: block;
        height: 100%;
        border: none;
        outline: none;
        width: 100%;
        padding-right: 20px;
        box-sizing: border-box;
      }
      &.textarea {
        height: auto;
        textarea {
          padding: 15px;
          box-sizing: border-box;
          border: 1px solid #b7b7b7;
          width: 100%;
        }
      }
    }
    .btn {
      width: 130px;
      height: 50px;
      background: #fff100;
      margin: 20px 0 20px 20px;
      text-align: center;
      font-size: 20px;
      line-height: 50px;
      cursor: pointer;
    }
  }
}
</style>
