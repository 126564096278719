<template>
  <div class="yusu-footer">
    <section class="yusu-footer-top">
      <div class="yusu-footer-item">
        <div class="yusu-footer-item-col">
          <div class="yusu-footer-item-col-item">服务</div>
          <div class="yusu-footer-item-col-item">联系我们：4008829959</div>
        </div>
        <div class="yusu-footer-item-col">
          <div class="yusu-footer-item-col-item"
            style="cursor: pointer"
            @click="onGotoAboutUs">关于我们</div>
          <div
            class="yusu-footer-item-col-item"
            style="cursor: pointer"
            @click="onGotoPage('/story')"
          >
            企业信息
          </div>
        </div>
        <div class="yusu-footer-item-col">
          <div class="yusu-footer-item-col-item">如何购买</div>
          <div class="yusu-footer-item-col-item">购买商城</div>
        </div>
        <div class="yusu-footer-item-col">
          <div class="yusu-footer-item-col-item">法律&隐私</div>
          <div
            class="yusu-footer-item-col-item"
            style="cursor: pointer"
            @click="onGotoRights"
          >
            电商维权
          </div>
        </div>
      </div>
      <div class="yusu-qrcode">
        <img
          class="img"
          :src="xiaohongshu"
          alt=""
          @click="
            openWindow(
              'https://www.xiaohongshu.com/user/profile/5c03b3260000000007012198?xhsshare=CopyLink&appuid=59cdf4f96eea887776cffb95&apptime=1619061238'
            )
          "
        />
        <img
          class="img"
          :src="gzh"
          alt=""
        />
        <img
          class="img"
          :src="weibo"
          alt=""
          @click="openWindow('https://weibo.com/2575075652/manage')"
        />
      </div>
    </section>
    <section class="yusu-footer-bottom">
      <div>Copyright © YOU&PURE All right reserved.</div>
      <a href="http://beian.miit.gov.cn/" target="_blank"
        >粤ICP备18051915号-5 电子营业执照</a
      >
    </section>
  </div>
</template>

<script>
import weibo from '../assets/images/weibo.png'
import gzh from '../assets/images/gzh.png'
import xiaohongshu from '../assets/images/xiaohongshu.png'
export default {
  name: 'footerComponent',
  data: () => {
    return {
      xiaohongshu: xiaohongshu,
      gzh: gzh,
      weibo: weibo,
    }
  },
  methods: {
    onGotoPage(url) {
      this.$router.push(url)
    },
    openWindow(url) {
      window.open(url, '_blank')
    },
    onGotoRights() {
      this.$router.push({
        path: '/electricity-rights',
      })
    },
    onGotoAboutUs() {
      this.$router.push({
        path: '/about-us',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.yusu-footer {
  padding: 60px 125px 20px 125px;
  font-size: 20px;
  color: rgb(62, 58, 57);
  .yusu-footer-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
    .yusu-footer-item {
      width: 1000px;
      display: flex;
      justify-content: space-between;
      .yusu-footer-item-col {
        padding: 40px;
        font-size: 20px;
        color: rgb(62, 58, 57);
        .yusu-footer-item-col-item {
          padding: 20px 0;
        }
      }
    }
    .yusu-qrcode {
      width: 900px;
      .img {
        width: 30%;
        margin: 0 10px;
        cursor: pointer;
      }
    }
  }
  .yusu-footer-bottom {
    display: flex;
    justify-content: space-between;
  }
}
</style>

