<template>
  <div id="app">
    <!-- <div class="header">
      <div id="nav">
        <router-link to="/">Home</router-link> |
        <router-link to="/news">news</router-link>
        <router-link to="/progress">Progress</router-link>
        <router-link to="/story">story</router-link>
        <router-link to="/welfare">welfare</router-link>
        <router-link to="/expert">expert</router-link>
        <router-link to="/free">free</router-link>
        <router-link to="/star">star</router-link>
        
      </div>
    </div> -->
    <headerComponent></headerComponent>
    <div class="container">
      <chatPop></chatPop>
      <router-view />
    </div>
    <footerComponent></footerComponent>
  </div>
</template>

<script>
import chatPop from './layout/chat-pop'
import headerComponent from './layout/header'
import footerComponent from './layout/footer'

export default {
  name: 'app',
  components: { headerComponent, footerComponent, chatPop },
}
</script>
<style>
</style>
