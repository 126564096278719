<template>
  <div class="yusu-common-header">
    <img :src="logoUrl" alt="" class="yusu-common-header-logo" />
    <section class="yusu-common-header-left">
      <img :src="douUrl" alt="" />
    </section>
    <section class="yusu-common-header-right">
      <nav class="yusu-common-header-main">
        <li class="menu-item">
          <div
            class="menu-item-text"
            :class="{ active: this.$route.path == '/' }"
            @click="onGoPage('/')"
          >
            首页
          </div>
        </li>
        <li class="menu-item">
          <div
            class="menu-item-text"
            :class="{ active: this.$route.path == '/top10' }"
            @click="onGoPage('/top10')"
          >
            人气Top10
          </div>
        </li>
        <li class="menu-item">
          <div
            class="menu-item-text"
            :class="{
              active: ['/story', '/expert', '/progress', '/news'].includes(
                this.$route.path
              ),
            }"
          >
            一起来搬专
          </div>
          <div class="menu-item-sub">
            <div class="menu-item-sub-item" @click="onGoPage('/story')">
              品牌故事
            </div>
            <div class="menu-item-sub-item" @click="onGoPage('/expert')">
              专家团
            </div>
            <div class="menu-item-sub-item" @click="onGoPage('/progress')">
              品牌历程
            </div>
            <div class="menu-item-sub-item" @click="onGoPage('/news')">
              品牌动态
            </div>
          </div>
        </li>
        <li class="menu-item">
          <div
            class="menu-item-text"
            :class="{
              active: ['/star'].includes(this.$route.path),
            }"
          >
            羽素星球图鉴
          </div>
          <div class="menu-item-sub">
            <div class="menu-item-sub-item" @click="onGoPage('/star')">
              成分星球
            </div>
            <div class="menu-item-sub-item" @click="onGoPage('/star')">
              羽素星人地图
            </div>
          </div>
        </li>
        <li class="menu-item">
          <div
            class="menu-item-text"
            :class="{
              active: [
                '/tailor-made-solutions-acne',
                '/tailor-made-solutions-sensitive',
              ].includes(this.$route.path),
            }"
          >
            量肤搭配方案
          </div>
          <div class="menu-item-sub">
            <div
              class="menu-item-sub-item"
              @click="onGoPage('/tailor-made-solutions-acne')"
            >
              根源祛痘
            </div>
            <div
              class="menu-item-sub-item"
              @click="onGoPage('/tailor-made-solutions-sensitive')"
            >
              敏感修护
            </div>
          </div>
        </li>
        <li class="menu-item">
          <div
            class="menu-item-text"
            :class="{
              active: ['/walking', '/talent'].includes(this.$route.path),
            }"
          >
            行走种草机
          </div>
          <div class="menu-item-sub">
            <div class="menu-item-sub-item" @click="onGoPage('/walking')">
              自媒体阵地
            </div>
            <div class="menu-item-sub-item" @click="onGoPage('/talent')">
              达人推荐
            </div>
          </div>
        </li>
        <li class="menu-item">
          <div
            class="menu-item-text"
            :class="{
              active: ['/welfare', '/free'].includes(this.$route.path),
            }"
          >
            隐藏撸羊毛攻略
          </div>
          <div class="menu-item-sub">
            <div class="menu-item-sub-item" @click="onGoPage('/welfare')">
              付邮试用
            </div>
            <div class="menu-item-sub-item" @click="onGoPage('/free')">
              正装免费送
            </div>
          </div>
        </li>
        <li class="menu-item">
          <div class="menu-item-text">
            <img :src="languageUrl" alt="" />
          </div>
        </li>
      </nav>
    </section>
  </div>
</template>

<script>
import logoUrl from '../assets/images/logo.png'
import douUrl from '../assets/images/dou.png'
import languageUrl from '../assets/images/language.png'
export default {
  name: 'headerComponent',
  data: () => {
    return { logoUrl: logoUrl, douUrl: douUrl, languageUrl: languageUrl }
  },
  methods: {
    onGoPage(url) {
      if (url == this.$route.path) return
      this.$router.push({
        path: url,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@keyframes bottomLine {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.yusu-common-header {
  height: 220px;
  width: 100%;
  background: #fff100;
  display: flex;
  justify-content: flex-start;
  position: relative;
  .yusu-common-header-logo {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, 0);
    width: 265px;
    height: 39px;
  }
  .yusu-common-header-left {
    width: 100px;
    padding: 20px;
  }
  .yusu-common-header-right {
    width: 1820px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .yusu-common-header-main {
      display: flex;
      justify-content: space-around;
      .menu-item {
        list-style: none;
        font-size: 23px;
        color: rgb(62, 58, 57);
        position: relative;
        cursor: pointer;
        .menu-item-text {
          padding-bottom: 20px;
          position: relative;
          img {
            width: 136px;
            height: 28px;
          }
          &.active {
            &::after {
              content: '';
              display: block;
              position: absolute;
              bottom: 10px;
              width: 100%;
              height: 3px;
              background: #fff;
            }
          }
          &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 10px;
            width: 0%;
            height: 3px;
            background: #fff;
          }
        }

        &:hover {
          .menu-item-sub {
            display: block;
          }
          .menu-item-text {
            position: relative;
            &::after {
              transition: width 0.26s ease 0s;
              width: 100% !important;
            }
          }
        }
        .menu-item-sub {
          display: none;
          position: absolute;
          background: #fff;
          width: 100%;
          z-index: 9999999;
          .menu-item-sub-item {
            padding: 15px 0;
            font-size: 20px;
            color: rgb(62, 58, 57);
            text-align: center;
            &:hover {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>
