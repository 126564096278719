var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"yusu-common-header"},[_c('img',{staticClass:"yusu-common-header-logo",attrs:{"src":_vm.logoUrl,"alt":""}}),_c('section',{staticClass:"yusu-common-header-left"},[_c('img',{attrs:{"src":_vm.douUrl,"alt":""}})]),_c('section',{staticClass:"yusu-common-header-right"},[_c('nav',{staticClass:"yusu-common-header-main"},[_c('li',{staticClass:"menu-item"},[_c('div',{staticClass:"menu-item-text",class:{ active: this.$route.path == '/' },on:{"click":function($event){return _vm.onGoPage('/')}}},[_vm._v(" 首页 ")])]),_c('li',{staticClass:"menu-item"},[_c('div',{staticClass:"menu-item-text",class:{ active: this.$route.path == '/top10' },on:{"click":function($event){return _vm.onGoPage('/top10')}}},[_vm._v(" 人气Top10 ")])]),_c('li',{staticClass:"menu-item"},[_c('div',{staticClass:"menu-item-text",class:{
            active: ['/story', '/expert', '/progress', '/news'].includes(
              this.$route.path
            ),
          }},[_vm._v(" 一起来搬专 ")]),_c('div',{staticClass:"menu-item-sub"},[_c('div',{staticClass:"menu-item-sub-item",on:{"click":function($event){return _vm.onGoPage('/story')}}},[_vm._v(" 品牌故事 ")]),_c('div',{staticClass:"menu-item-sub-item",on:{"click":function($event){return _vm.onGoPage('/expert')}}},[_vm._v(" 专家团 ")]),_c('div',{staticClass:"menu-item-sub-item",on:{"click":function($event){return _vm.onGoPage('/progress')}}},[_vm._v(" 品牌历程 ")]),_c('div',{staticClass:"menu-item-sub-item",on:{"click":function($event){return _vm.onGoPage('/news')}}},[_vm._v(" 品牌动态 ")])])]),_c('li',{staticClass:"menu-item"},[_c('div',{staticClass:"menu-item-text",class:{
            active: ['/star'].includes(this.$route.path),
          }},[_vm._v(" 羽素星球图鉴 ")]),_c('div',{staticClass:"menu-item-sub"},[_c('div',{staticClass:"menu-item-sub-item",on:{"click":function($event){return _vm.onGoPage('/star')}}},[_vm._v(" 成分星球 ")]),_c('div',{staticClass:"menu-item-sub-item",on:{"click":function($event){return _vm.onGoPage('/star')}}},[_vm._v(" 羽素星人地图 ")])])]),_c('li',{staticClass:"menu-item"},[_c('div',{staticClass:"menu-item-text",class:{
            active: [
              '/tailor-made-solutions-acne',
              '/tailor-made-solutions-sensitive' ].includes(this.$route.path),
          }},[_vm._v(" 量肤搭配方案 ")]),_c('div',{staticClass:"menu-item-sub"},[_c('div',{staticClass:"menu-item-sub-item",on:{"click":function($event){return _vm.onGoPage('/tailor-made-solutions-acne')}}},[_vm._v(" 根源祛痘 ")]),_c('div',{staticClass:"menu-item-sub-item",on:{"click":function($event){return _vm.onGoPage('/tailor-made-solutions-sensitive')}}},[_vm._v(" 敏感修护 ")])])]),_c('li',{staticClass:"menu-item"},[_c('div',{staticClass:"menu-item-text",class:{
            active: ['/walking', '/talent'].includes(this.$route.path),
          }},[_vm._v(" 行走种草机 ")]),_c('div',{staticClass:"menu-item-sub"},[_c('div',{staticClass:"menu-item-sub-item",on:{"click":function($event){return _vm.onGoPage('/walking')}}},[_vm._v(" 自媒体阵地 ")]),_c('div',{staticClass:"menu-item-sub-item",on:{"click":function($event){return _vm.onGoPage('/talent')}}},[_vm._v(" 达人推荐 ")])])]),_c('li',{staticClass:"menu-item"},[_c('div',{staticClass:"menu-item-text",class:{
            active: ['/welfare', '/free'].includes(this.$route.path),
          }},[_vm._v(" 隐藏撸羊毛攻略 ")]),_c('div',{staticClass:"menu-item-sub"},[_c('div',{staticClass:"menu-item-sub-item",on:{"click":function($event){return _vm.onGoPage('/welfare')}}},[_vm._v(" 付邮试用 ")]),_c('div',{staticClass:"menu-item-sub-item",on:{"click":function($event){return _vm.onGoPage('/free')}}},[_vm._v(" 正装免费送 ")])])]),_c('li',{staticClass:"menu-item"},[_c('div',{staticClass:"menu-item-text"},[_c('img',{attrs:{"src":_vm.languageUrl,"alt":""}})])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }