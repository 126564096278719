// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../font/SourceHanSansCN-Regular.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "*{padding:0;margin:0}@font-face{font-family:SourceHanSan;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\");font-weight:400}body{line-height:1;font-size:.08333rem;font-family:SourceHanSan}img{max-width:100%;vertical-align:middle}a{text-decoration:none}a,a:visited{color:#3e3a39}.flex{display:flex}.flexItem{flex:1}", ""]);
// Exports
module.exports = exports;
