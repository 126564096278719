import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/index.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: '羽素，量肤解决年轻肌肤问题！',
    },
  },
  {
    path: '/top10',
    name: 'Top10',
    component: () => import('../views/popular-top10/index.vue'),
    meta: {
      title: 'top10',
    },
  },
  {
    path: '/top10/detail',
    name: 'Top10-detail',
    component: () => import('../views/popular-top10/detail.vue'),
    meta: {
      title: 'top10详情',
    },
  },
  {
    path: '/walking',
    name: 'walking',
    component: () => import('../views/walking-grass-planter/index.vue'),
    meta: {
      title: '自媒体阵地',
    },
  },
  {
    path: '/talent',
    name: 'talent',
    meta: {
      title: '达人推荐',
    },
    component: () => import('../views/walking-grass-planter/talent.vue'),
  },
  {
    path: '/tailor-made-solutions-acne',
    name: 'tailor-made-solutions-acne',
    component: () => import('../views/tailor-made-solutions/acne.vue'),
    meta: {
      title: '根源祛痘',
    },
  },
  {
    path: '/tailor-made-solutions-sensitive',
    name: 'tailor-made-solutions-sensitive',
    component: () => import('../views/tailor-made-solutions/sensitive.vue'),
    meta: {
      title: '敏感修复',
    },
  },
  {
    path: '/news',
    name: 'news',
    meta: {
      title: '品牌动态',
    },
    component: () => import( /* webpackChunkName: "news" */ '../views/banzhuan/News.vue')
  },
  {
    path: '/story',
    name: 'story',
    meta: {
      title: '品牌故事',
    },
    component: () => import( /* webpackChunkName: "story" */ '../views/banzhuan/Story.vue')
  },
  {
    path: '/progress',
    name: 'progress',
    meta: {
      title: '品牌进程',
    },
    component: () => import( /* webpackChunkName: "progress" */ '../views/banzhuan/Progress.vue')
  },
  {
    path: '/progress2019',
    name: 'progress2019',
    meta: {
      title: '品牌进程2019',
    },
    component: () => import( /* webpackChunkName: "progress2019" */ '../views/banzhuan/Year2019.vue')
  },
  {
    path: '/progress2020',
    name: 'progress2020',
    meta: {
      title: '品牌进程2020',
    },
    component: () => import( /* webpackChunkName: "progress2020" */ '../views/banzhuan/Year2020.vue')
  },
  {
    path: '/progress2021',
    name: 'progress2021',
    meta: {
      title: '品牌进程2021',
    },
    component: () => import( /* webpackChunkName: "progress2021" */ '../views/banzhuan/Year2021.vue')
  },
  {
    path: '/expert',
    name: 'expert',
    meta: {
      title: '专家团',
    },
    component: () => import( /* webpackChunkName: "expert" */ '../views/banzhuan/Expert.vue')
  },
  {
    path: '/welfare',
    name: 'welfare',
    meta: {
      title: '粉丝福利付邮试用',
    },
    component: () => import( /* webpackChunkName: "welfare" */ '../views/luyangmao/Welfare.vue')
  },
  {
    path: '/free',
    name: 'free',
    meta: {
      title: '正装免费送',
    },
    component: () => import( /* webpackChunkName: "free" */ '../views/luyangmao/Free.vue')
  },
  {
    path: '/star',
    name: 'star',
    meta: {
      title: '战痘星球',
    },
    component: () => import( /* webpackChunkName: "star" */ '../views/Star.vue')
  },
  {
    path: '/award',
    name: 'award',
    meta: {
      title: '新浪获奖',
    },
    component: () => import( /* webpackChunkName: "award" */ '../views/ad/Award.vue')
  },
  {
    path: '/yonglegong',
    name: 'yonglegong',
    meta: {
      title: '羽素又双叒叕搞了永乐宫',
    },
    component: () => import( /* webpackChunkName: "yonglegong" */ '../views/ad/Yonglegong.vue')
  },
  {
    path: '/electricity-rights',
    name: 'electricity-rights',
    meta: {
      title: '维权专用页面',
    },
    component: () => import( /* webpackChunkName: "yonglegong" */ '../views/electricity-rights.vue')
  },
  {
    path: '/about-us',
    name: 'about-us',
    meta: {
      title: '关于我们',
    },
    component: () => import( /* webpackChunkName: "aboutus" */ '../views/AboutUs.vue')
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  document.documentElement.scrollTop = 0
  next()
})

router.afterEach((to) => {
  // set page title
  console.log(to, 333)
  document.title = to.meta.title
  document.documentElement.scrollTop = document.body.scrollTop = 0
})

export default router